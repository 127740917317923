<template>
    <div>
        <!-- <div class="tour_navigation">
            <div>
                <v-select
                    label="Экскурсии"
                    :items='excursions'
                    height="48"
                    outlined
                    hide-details
                    dense
                >
                </v-select>
            </div>
            <v-btn
                color="primary"
                height="48"
                class="px-5 ml-5"
            >
                Поиск
            </v-btn>
        </div> -->
        <v-data-table
            :headers="headers"
            :items="tourRoutes.results"
            :loading='loading'
            disable-sort
            :options.sync="options"
            :server-items-length="tourRoutes.count"
        >
            <template v-slot:[`item.bus.bus_type`]="{ item }">
                {{item.bus.bus_type=='sitting' ? 'Сидячий' : 'Спальный'}}
            </template>
            <template v-slot:[`item.is_back`]="{ item }">
                <div style="pointer-events: none;">
                    <v-checkbox
                        v-model="item.is_back"
                        hide-details
                        readonly
                        dense
                        class="mb-2"
                    >
                    </v-checkbox>
                </div>
            </template>
            <template v-slot:[`item.from_date`]="{ item }">
                {{$moment(item.from_date*1000).format("DD MMM YYYY HH:mm")}}
            </template>
            <template v-slot:[`item.to_date`]="{ item }">
                {{$moment(item.to_date*1000).format("DD MMM YYYY HH:mm")}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="table_actions">
                    <v-btn @click="onCopy(item)" icon>
                        <v-icon size="20">mdi-content-copy</v-icon>
                    </v-btn>
                    <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)'/>
                </div>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' :hideButton='true'>
            <v-tabs-items v-model="tab" class="pa-1">
                <v-tab-item>
                    <v-form
                        ref="first_form"
                        lazy-validation
                    >
                        <input-content
                            :items="inputItems"
                            :model="model"
                            :dialog='dialog'
                        />
                    </v-form>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="nextStep"
                    >
                        Далее
                    </v-btn>
                </v-tab-item>
                <v-tab-item>
                    <multiple-create ref="hotelsCreate" :dialog='dialog' :inputItems='hotelInputs'/>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4 mr-2"
                        @click="tab = 0"
                    >
                        Назад
                    </v-btn>
                    <v-btn
                        color="primary"
                        height="44"
                        width="160"
                        class="mt-4"
                        @click="onSubmit"
                    >
                        Сохранить
                    </v-btn>
                </v-tab-item>
            </v-tabs-items>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить маршрут?"'/>
    </div>
</template>

<script>
import VueDialog from '@/components/ui/vueDialog.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import multipleCreate from '@/components/ui/multipleCreate.vue'
import {mapActions, mapGetters} from 'vuex'
import { busesService } from '@/services/buses.service.js'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        VueDialog,
        InputContent,
        vueDeleteDialog,
        VueTableActions,
        multipleCreate
    },
    data: () => ({
        busHotels: [],
        tourRoutes: {},
        loading: false,
        deleteDialog: false,
        tab: 0,
        editId: null,
        deleteId: null,
        inputItems: [
            {
                type: 'select',
                label: 'Откуда',
                items: [],
                model: 'from_location',
                getItems: 'getBusCities',
                rules: 'required',
                cols: 6
            },
            {
                type: 'select',
                label: 'Куда',
                items: [],
                model: 'to_location',
                getItems: 'getBusCities',
                rules: 'required',
                cols: 6
            },
            {
                type: 'date',
                label: 'Даты отправления',
                model: 'tourdates',
                multiple: true,
                rules: 'required',
                cols: 6
            },
            {
                type: 'select',
                label: 'Номер автобуса',
                model: 'bus',
                multiple: false,
                getItems: 'getMainBuses',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Время отправления',
                model: 'time',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'День прибытия',
                model: 'days',
                rules: 'required',
                cols: 3
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Прибытие',
                model: 'next_time',
                rules: 'required',
                cols: 3
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость взрослого OW',
                model: 'adult_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость взрослого RT',
                model: 'adult_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость детского OW',
                model: 'child_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость детского RT',
                model: 'child_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость пенсионера OW',
                model: 'pensioner_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Стоимость пенсионера RT',
                model: 'pensioner_rt_ticket',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст ребёнка',
                model: 'child_age',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'Возраст пенсионера',
                model: 'pensioner_age',
                rules: 'required',
                cols: 6
            },
            {
                type: 'checkbox',
                label: 'Обратно?',
                model: 'is_back',
                rules: 'required',
                cols: 6
            },
        ],
        hotelInputs: [
            {
                type: 'select',
                label: 'Отель',
                items: [],
                model: 'hotel',
                getItems: 'getHotels',
                rules: 'required',
            },
            {
                type: 'text',
                inputType: 'number',
                label: 'День прибытия',
                model: 'arrival_day',
                rules: 'required',
                cols: 6
            },
            {
                type: 'text',
                inputType: 'time',
                label: 'Прибытие',
                model: 'arrival_time',
                rules: 'required',
                cols: 6
            },
        ],
        model: {},
        dialog: false,
        dialogOptions: {
            width: '700',
            title: 'Маршрут',
        },
        excursions: [],
        headers: [
            { text: 'Автобус', value: 'bus.number' },
            { text: 'Тип', value: 'bus.bus_type' },
            { text: 'Обратно', value: 'is_back' },
            { text: 'Откуда', value: 'from_location.name' },
            { text: 'Куда', value: 'to_location.name' },
            { text: 'Отправление', value: 'from_date' },
            { text: 'Прибытие', value: 'to_date' },
            { text: 'Взрослый', value: 'adult_ticket' },
            { text: 'Детский', value: 'child_ticket' },
            { text: 'Пенсионер', value: 'pensioner_ticket' },
            { text: '', value: 'actions' },
        ],
        items: [],
    }),
    watch: {
        dialog(val) {
            if (val) {
                if (this.$refs.first_form) {
                    this.$refs.first_form.resetValidation()
                }
            }
        }
    },
    methods: {
        async onCopy(item) {
            this.$loading(true)
            this.tab = 0
            this.model = {}
            this.model = this.$copy(item)
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = true
                }
            })
            this.model.days = Math.floor((+item.to_date - +item.from_date) / (3600*24));
            let hours_minutes = this.$moment(new Date(+item.to_date * 1000)).format('HH:mm')
            this.model.next_time = hours_minutes
            // this.model.tourdates = this.$moment(item.from_date*1000).format('YYYY-MM-DD')
            this.model.time = this.$moment(item.from_date*1000).format('HH:mm')
            const res = await busesService.getSingleBusRoutes(item.id)
            const temp = res.bus_hotels.map(el => {
                return {
                    id: el.id,
                    arrival_day: el.arrival_day,
                    arrival_time: el.arrival_time, 
                    hotel: el.hotel.id ?? el.hotel
                }
            })
            this.busHotels = this.$copy(temp)
            this.$loading(false)
            this.model.id = null
            this.dialog = true
        },
        async getList() {
            this.loading = true
            let params = {
                page: this.options.page, 
                size: this.options.itemsPerPage
            }
            this.tourRoutes = await busesService.getBusRoutes(params)
            this.loading = false
        },
        nextStep() {
            if (!this.$refs.first_form.validate()) return
            this.tab = 1
            if (this.model.is_back) {
                this.hotelInputs.find(el => el.model=='arrival_day').label = 'День отправления'
                this.hotelInputs.find(el => el.model=='arrival_time').label = 'Отправление'
            } else {
                this.hotelInputs.find(el => el.model=='arrival_day').label = 'День прибытия'
                this.hotelInputs.find(el => el.model=='arrival_time').label = 'Прибытие'
            }
            setTimeout(() => {
                this.$refs.hotelsCreate.setModel(this.busHotels)
            }, 0)
        },
        closeDialog() {
            if (this.$refs.first_form) {
                this.$refs.first_form.reset()
            }
            this.busHotels = []
            this.dialog = false
            this.model = {}
        },
        onAdd() {
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = true
                }
            })
            this.tab = 0
            this.model = {}
            this.dialog = true
        },
        async onEdit(item) {
            this.$loading(true)
            this.tab = 0
            this.model = {}
            this.model = this.$copy(item)
            this.inputItems.forEach(el => {
                if (el.model == 'tourdates') {
                    el.multiple = false
                }
            })
            this.model.days = Math.floor((+item.to_date - +item.from_date) / (3600*24));
            let hours_minutes = this.$moment(new Date(+item.to_date * 1000)).format('HH:mm')
            this.model.next_time = hours_minutes
            this.model.tourdates = this.$moment(item.from_date*1000).format('YYYY-MM-DD')
            this.model.time = this.$moment(item.from_date*1000).format('HH:mm')
            const res = await busesService.getSingleBusRoutes(item.id)
            const temp = res.bus_hotels.map(el => {
                return {
                    id: el.id,
                    arrival_day: el.arrival_day,
                    arrival_time: el.arrival_time, 
                    hotel: el.hotel.id ?? el.hotel
                }
            })
            this.busHotels = this.$copy(temp)
            this.$loading(false)
            this.dialog = true
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await busesService.deleteBusRoute(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async patchRoute() {
            const patchHotelsPromise = []
            const postHotelPromise = []
            const deleteHotelPromise = []
            this.$refs.hotelsCreate.getModel()
            if (!this.busHotels.length) return
            this.busHotels.forEach(el => {
                el.hotel = el.hotel.id ?? el.hotel
                let h = parseInt((el.arrival_time) / 3600)
                let m = parseInt(((el.arrival_time) % 3600) / 60)  
                console.log(el.arrival_time);
                console.log(h, m); 
                console.log(`${h<9?'0'+h:h}:${m<9?'0'+m:m}`);
                el.travel_time = Number(el.arrival_day)*86400 + Number(el.arrival_time)
                el.arrival_time = `${h<9?'0'+h:h}:${m<9?'0'+m:m}`
                el.hours = new Date().getHours() - new Date().getUTCHours()
                el.minutes = new Date().getMinutes() - new Date().getUTCMinutes()
                if (el.id) {
                    patchHotelsPromise.push(
                        busesService.patchBusHotel({...el, route: this.model.id})
                    )
                } else {
                    postHotelPromise.push(
                        busesService.postBusHotel({...el, route: this.model.id})
                    )
                }
            })
            // const oldHotels = (await busesService.getSingleBusRoutes(this.model.id)).bus_hotels
            // const deleteIds = oldHotels.filter(el => !this.busHotels.map(item => item.id).includes(el.id)).map (el => el.id)
            // deleteIds.forEach(id => {
            //     deleteHotelPromise.push(
            //         busesService.deleteBusHotel(id)
            //     )
            // })
            await Promise.all(patchHotelsPromise)
            await Promise.all(postHotelPromise)
            // await Promise.all(deleteHotelPromise)
            this.model.bus = this.model.bus.id ?? this.model.bus
            this.model.from_location = this.model.from_location.id ?? this.model.from_location
            this.model.to_location = this.model.to_location.id ?? this.model.to_location
            this.model.from_date = this.$moment(this.model.tourdates)
                                        .add(this.model.time.split(':')[0], 'hour')
                                        .add(this.model.time.split(':')[1], 'minutes')
                                        .locale("en")
                                        .valueOf() / 1000
            await busesService.patchBusRoute(this.model)
        },
        async postRoute() {
            let dates = []
            const multipleArr = this.$refs.hotelsCreate.getModel()
            const bus_hotels = multipleArr.map(el => {
                let h = parseInt((el.arrival_time) / 3600)
                let m = parseInt(((el.arrival_time) % 3600) / 60)
                console.log(el.arrival_time);
                console.log(h, m); 
                console.log(`${h<9?'0'+h:h}:${m<9?'0'+m:m}`);
                return {
                    hours: new Date().getHours() - new Date().getUTCHours(),
                    minutes: new Date().getMinutes() - new Date().getUTCMinutes(),
                    arrival_time: `${h<9?'0'+h:h}:${m<9?'0'+m:m}`, 
                    arrival_day: el.arrival_day, 
                    travel_time: (el.arrival_day*86400) + (el.arrival_time),
                    hotel: el.hotel.id ?? el.hotel
                }
            })
            this.model.tourdates.forEach(element => {
                let date = this.$moment(element)
                                .add(this.model.time.split(':')[0], 'hour')
                                .add(this.model.time.split(':')[1], 'minutes')
                                .locale("en")
                                .valueOf()
                dates.push(date/1000)
            });
            this.model.dates = dates
            this.model.from_location = this.model.from_location.id ?? this.model.from_location 
            this.model.to_location = this.model.to_location.id ?? this.model.to_location 
            this.model.bus = this.model.bus.id ?? this.model.bus
            await busesService.postBusRoutes({ ...this.model, bus_hotels})
        },
        async onSubmit() {
            if (!this.$refs.hotelsCreate.validate()) return
            try {
                this.$loading(true)
                let a = Number(this.model.days) * 86400 + (Number(this.model.next_time.split(':')[0]) *3600) + (Number(this.model.next_time.split(':')[1]) * 60)
                this.model.travel_time=a;
                if (this.model.id) {
                    await this.patchRoute()
                } else {
                    await this.postRoute()
                }
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally{
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tour_navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    div {
        width: 400px;
    }
}
.table_actions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
</style>