<template>
    <div>
        <div class="grid_view">
            <v-card
                v-for="(item, index) in model"
                :key="index"
                class="px-3 pb-3"
                :style="{paddingTop: index > 0 ? '' : '36px'}"
            >
                <div v-if="index > 0" class="text-right">
                    <v-btn icon @click="onDelete(index)">
                        <img src="@/assets/images/ui/delete.svg" alt="">
                    </v-btn>
                </div>
                <v-form
                    ref="form_hotel"
                    lazy-validation
                >
                    <input-content :model='model[index]' :items='inputItems'/>
                </v-form>
            </v-card>
        </div>
        <div class="text-right mt-3">
            <v-btn
                small
                outlined
                color="primary"
                @click="onAdd"
            >
                Добавить
            </v-btn>
        </div>
    </div>
</template>

<script>
import VueTableActions from '@/components/ui/vueTableActions.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import { busesService } from '@/services/buses.service.js'

export default {
    props: {
        dialog: Boolean,
        inputItems: Array
    },
    components: {
        VueTableActions,
        InputContent
    },
    watch: {
        dialog(val) {
            if (!val) {
                if (this.$refs.form_hotel) {
                    this.$refs.form_hotel.forEach(form => {
                        form.reset()
                    })
                }
                this.model = [{}]
            }
        }
    },
    data: () => ({
        model: [{}],
        item: {},
    }),
    methods: {
        validate() {
            let is_valid = false
            if (this.$refs.form_hotel) {
                this.$refs.form_hotel.forEach(form => {
                    if (form.validate()) {
                        is_valid = true
                    }
                })
            }
            return is_valid
        },
        setModel(model) {
            this.model = model
            if (model.length == 0) {
                this.model.push({})
            }
        },
        getModel() {
            this.model.forEach(el => {
                el.arrival_time = el.arrival_time.split(':')[0]*3600 + el.arrival_time.split(':')[1]*60
            })
            return this.model
        },
        async onDelete(index) {
            if(!this.model[index].id) this.model.splice(index, 1)
            else {
                await busesService.deleteBusHotel(this.model[index].id)
                this.model.splice(index, 1)
            }
        },
        onAdd() {
            this.model.push({})
        },
    }
}
</script>

<style lang="scss" scoped>
.grid_view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-gap: 20px;
}
</style>