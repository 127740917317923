<template>
    <div>
        <v-data-table
            :headers="busHeaders"
            :items="busItems.results"
            :loading="loading"
            disable-sort
            :options.sync="options"
            :server-items-length="busItems.count"
        >
            <template v-slot:[`item.bus_type`]="{ item }">
                {{item.bus_type=='sitting' ? 'Сидячий' : 'Спальный'}}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <vue-table-actions :item='item' @onEdit='onEdit(item)' @onDelete='onDelete(item)' :eyeEdit='true'/>
            </template>
        </v-data-table>
        <vue-dialog :model='dialog' :options='dialogOptions' @closeDialog='closeDialog' @onSubmit='onSubmit' :hideButton='Boolean(editId)'>
            <v-form
                ref="form"
                class="bus_form"
                lazy-validation
            >
                <v-select
                    v-model="busType"
                    :items='busTypes'
                    label="Тип автобуса"
                    class="mb-4"
                    height="44"
                    hide-details
                    outlined
                    dense
                    :rules="[$rules.required]"
                    :readonly='Boolean(editId)'
                    @change="changeBusType(busType)"
                >
                </v-select>
                <v-text-field
                    v-model="busNumber"
                    label="Номер автобуса"
                    height="44"
                    hide-details
                    outlined
                    class="mb-4"
                    dense
                    :rules="[$rules.required]"
                    :readonly='Boolean(editId)'
                >
                </v-text-field>
                <div v-if="busType==='sitting'">
                    <input-content
                        :items="inputItems"
                        :model="model"
                    />
                    <bus-model />
                </div>
                <div v-else>
                    <v-select
                        v-if="Boolean(editId)"
                        v-model="editSeatCount"
                        :items='sleepingOptions'
                        label="Кол-во мест"
                        class="mb-4"
                        height="44"
                        hide-details
                        outlined
                        dense
                        :rules="[$rules.required]"
                        :readonly='Boolean(editId)'
                    >
                    </v-select>
                    <v-select
                        v-else
                        v-model="model.seatCount"
                        :items='sleepingOptions'
                        label="Кол-во мест"
                        class="mb-4"
                        height="44"
                        hide-details
                        outlined
                        dense
                        :rules="[$rules.required]"
                        :readonly='Boolean(editId)'
                    >
                    </v-select>
                    <bus-model ref="slepping" :isSleeping="true" />
                    <div class="mb-2"></div>
                </div>
            </v-form>
        </vue-dialog>
        <vue-delete-dialog :model='deleteDialog' @onClick='deleteHandler' @closeDialog='closeDelete' :text='"Вы уверены что хотите удалить автобус?"'/>
    </div>
</template>

<script>
import BusModel from '@/components/buses/busModel.vue'
import VueAddButton from '@/components/ui/vueAddButton.vue'
import VueTableActions from '@/components/ui/vueTableActions.vue'
import VueDialog from '@/components/ui/vueDialog.vue'
import InputContent from '@/components/inputs/inputContent.vue'
import vueDeleteDialog from '@/components/ui/vueDeleteDialog.vue'
import {mapActions, mapMutations, mapGetters} from 'vuex'
import { busesService } from '@/services/buses.service.js'
import pagination from "@/utils/pagination"
export default {
    mixins: [pagination],
    components: {
        BusModel,
        VueAddButton,
        VueTableActions,
        VueDialog,
        vueDeleteDialog,
        InputContent
    },
    data: () => ({
        editSeatCount: 32,
        busModel: null,
        loading: false,
        editBus: [],
        editId: null,
        deleteId: null,
        deleteDialog: false,
        busNumber: '',
        busType: 'sitting',
        sleepingModel: {
            seatCount: 32
        },
        sleepingOptions: [32, 36],
        busTypes: [
            {text: 'Сидячий', value: 'sitting'},
            {text: 'Спальный', value: 'sleeping'}
        ],
        dialogOptions: {
            width: '1000',
            title: 'Автобус',
            button: 'Сохранить'
        },
        inputItems: [
            {
                type: 'text',
                label: 'Кол-во мест',
                rules: 'required',
                model: 'seatCount',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'text',
                label: 'Мест сверху',
                rules: 'required',
                model: 'top',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'text',
                label: 'Мест снизу',
                rules: 'required',
                model: 'bottom',
                inputType: 'number',
                cols: 6
            },
            {
                type: 'checkbox',
                label: 'Задний ряд',
                rules: 'required',
                model: 'is_back',
                inputType: 'number',
                cols: 6
            }
        ],
        dialog: false,
        busItems: [],
        busHeaders: [
            { text: 'Автобус', value: 'number' },
            { text: 'Тип', value: 'bus_type' },
            { text: '', value: 'actions' },
        ],
    }),
    computed: {
        ...mapGetters({
            vuexBusModel: 'busModel/getModel',
            postBusModel: 'busModel/postBusModel'
        }),
        model: {
            get() { return this.vuexBusModel },
            set(val) { this.setModel(val)}
        },
    },
    watch: {
        model: {
            handler() { this.setBusModel() },
            deep: true
        },
        dialog(){
            if(this.model.seatCount > 0) {
                this.inputItems = [
                    {
                        type: 'text',
                        label: 'Кол-во мест',
                        rules: 'required',
                        model: 'seatCount',
                        inputType: 'number',
                        cols: 6,
                        readonly: true, 
                    },
                ]
            }
            else {
                this.inputItems = [
                    {
                        type: 'text',
                        label: 'Кол-во мест',
                        rules: 'required',
                        model: 'seatCount',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'text',
                        label: 'Мест сверху',
                        rules: 'required',
                        model: 'top',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'text',
                        label: 'Мест снизу',
                        rules: 'required',
                        model: 'bottom',
                        inputType: 'number',
                        cols: 6
                    },
                    {
                        type: 'checkbox',
                        label: 'Задний ряд',
                        rules: 'required',
                        model: 'is_back',
                        inputType: 'number',
                        cols: 6
                    }
                ]
            }
        }
    },
    methods: {
        ...mapMutations({
            setSleepingModel: 'busModel/SET_SLEEPING_MODEL',
            setModel: 'busModel/SET_MODEL',
            setBusModel: 'busModel/SET_BUS_MODEL',
            setBackSeat: 'busModel/SET_BACK_SEAT',
            setBusType: 'busModel/SET_BUS_TYPE',
            translateFromBack: 'busModel/TRANSFORM_BUS_MODEL_FROM_BACK'
        }),
        ...mapActions({
            createBus: 'tours/createBus',
            getBuses: 'tours/getBuses',
        }),
        changeBusType(type) {
            if (type=='sleeping') {
                this.model = {
                    seatCount: 32
                }
            } else {
                this.model = {}
            }
            this.setBusType(type)
        },
        changeBusModel(model) {
            this.model.top = model.top
            this.model.bottom = model.bottom
            this.model.seatCount = model.seatCount
            this.model.is_back = model.is_back
        },
        async getList() {
            this.loading = true
            let params = {
                page: this.options.page, 
                size: this.options.itemsPerPage
            }
            const res = await busesService.getBuses(params)
            this.busItems = res
            this.loading = false
        },
        onAdd() {
            this.busModel = null
            this.busType = 'sitting',
            this.setBusType('sitting')
            this.busNumber = ''
            this.model = {}
            this.dialog = true
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
        },
        async onEdit(item) {
            try {
                this.$loading(true)
                this.model = {}
                this.editId = item.id
                this.inputItems.forEach(input => input.readonly = true)
                const res = await busesService.getSingleBus(item.id)
                this.busType = res.bus_type
                this.editBus = this.$copy(res.bus_seat_places)
                if (res.bus_type == 'sleeping') {
                    if (res.bus_seat_places.length == 18) {
                        this.editSeatCount = 32
                    } else {
                        this.editSeatCount = 36
                    }
                }
                this.translateFromBack(res)
                this.busNumber = res.number
                this.dialog = true
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        closeDelete() {
            this.deleteDialog = false
            this.deleteId = null
        },
        onDelete(item) {
            this.deleteId = item.id
            this.deleteDialog = true
        },
        closeDialog() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            this.dialog = false
            this.editId = null
            this.editBus = []
            this.inputItems.forEach(input => input.readonly = false)
        },
        async deleteHandler() {
            try {
                this.$loading(true)
                await busesService.deleteBus(this.deleteId)
                await this.getList()
            } catch (e) {
                console.error(e);
            } finally {
                this.closeDelete()
                this.$loading(false)
            }
        },
        async onSubmit() {
            if (this.$refs.form) {
                if (!this.$refs.form.validate()) return
            }
            try {
                this.$loading(true)
                const data = {
                    number: this.busNumber,
                    bus_type: this.busType,
                    is_layout: true,
                    bus_seat_places: this.postBusModel
                }
                await busesService.postBus(data)
                await this.getList()
            } catch(e) {
                console.error(e);
            } finally {
                this.dialog = false
                this.$loading(false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.bus_form {
    max-width: 500px;
}
</style>